<template>
  <a-layout>
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
      class="app-sider"
    >
      <div class="logo">
        <a-icon type="slack" />
        <h1>智慧水务系统</h1>
      </div>
      <SideMenu :openCurrent="openCurrent" @clearPath="clearPath"></SideMenu>
    </a-layout-sider>
    <a-layout :style="'width:' + (fullWidth - 200) + 'px'">
      <a-layout-header class="app-header" v-if="!showScreen">
        <span class="app-header-trigger">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
        </span>
        <!-- <span class="app-header-refresh">
          <a-icon type="reload"></a-icon>
        </span> -->
        <audio id="audio" preload="auto" loop>
          <source src="../assets/audio/14039.wav" type="audio/wav" />
        </audio>
        <div class="app-header-right">
          <a-menu mode="horizontal" @click="handleClick">
            <a-menu-item key="/alarm/deviation" v-if="showModel[0]">
              <a-badge :count="count"><a-icon type="bell" /></a-badge>
            </a-menu-item>
            <a-sub-menu>
              <span class="app-user-avatar" slot="title">
                <a-avatar
                  size="small"
                  src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                />
                {{ User ? User.username : "" }}
              </span>
              <a-menu-item key="/setting/profile" v-if="showModel[1]"
                ><a-icon type="user" key="user" />个人资料
              </a-menu-item>
              <a-menu-item key="/setting/password" v-if="showModel[2]"
                ><a-icon type="lock" key="password" />修改密码
              </a-menu-item>
              <a-menu-item key="/login"
                ><a-icon type="poweroff" />退出登录
              </a-menu-item>
            </a-sub-menu>
            <!-- <a-menu-item key="/help">
              <a-icon type="question-circle"
            /></a-menu-item> -->
          </a-menu>
        </div>
      </a-layout-header>
      <a-layout-content>
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapGetters } from "vuex";
import SideMenu from "../components/SideMenu";
var synth = window.speechSynthesis;
var msg = new SpeechSynthesisUtterance();
export default {
  name: "Home",
  data() {
    return {
      intervalId: null, // 定时器
      count: 0, // 告警未处理数
      fullWidth: document.documentElement.clientWidth,
      collapsed: false,
      nodesize: 1,
      departlimit: 1,
      showScreen: false,
      openCurrent: "",
      // 播放时间
      lastRunTime: null,
      // s是否正在播放
      isPlaying: false,
      showsList: [],
      rbList: [8, 49, 48],
      showModel: [false, false, false],
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["User"]),
  },
  mounted() {
    this.showWat();
    //监听屏幕宽度
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.fullWidth = window.fullWidth;
      })();
    };
    // that.checkPath();
    that.getUnitList();
    that.getNodeList();
    that.getTypeList();
    that.getDepartment();
    that.getDeviceMenu();
    that.getMenuManage();
    this.getwarningType();
    // that.dataRefreh();
    // 客户端初始化完成
    if (!this.$mqtt.client) {
      this.MQTTconnect();
    }
  },
  watch: {
    $route(to, from) {
      if (to.path == "/screen") {
        this.showScreen = true;
        this.collapsed = true;
      } else {
        this.showScreen = false;
        this.collapsed = false;
      }
      if (from.path == "/login") {
        this.MQTTconnect();
      }
    },
    count(val, old) {
      if (parseInt(val) > parseInt(old)) {
        // 有新消息产生
        this.isPlaying = false;
        // this.play();
      }
    },
  },
  methods: {
    // 控制显示跳转的地方
    showWat() {
      var arr = [...this.$store.getters.routes];
      this.showsList = [];
      arr[0].children.forEach((ele) => {
        this.showsList.push(ele.id);
        if (ele.children.length != 0) {
          ele.children.forEach((chil) => {
            this.showsList.push(chil.id);
          });
        }
      });
      this.rbList.forEach((element, index) => {
        this.showsList.forEach((eles) => {
          if (element == eles) {
            this.$set(this.showModel, index, true);
          }
        });
      });
    },
    // 声音控制
    play() {
      this.lastRunTime = Date.now();
      let audio = document.querySelector("#audio");
      if (!this.isPlaying) {
        audio.play();
        this.isPlaying = true;
      }
      let timeOut = setTimeout(() => {
        this.stop(timeOut);
      }, 3600);
    },
    stop(timeOut) {
      this.currentTime = Date.now();
      let audio = document.querySelector("#audio");
      if (this.currentTime - this.lastRunTime < 3600) {
      } else {
        if (this.isPlaying) {
          audio.currentTime = 0;
          audio.pause();
          this.isPlaying = false;
        }
      }
      clearTimeout(timeOut);
    },
    MQTTconnect() {
      if (this.$store.state.User.Authorization) {
        // this.$mqtt.subscribe(
        //   "device/+/update/data",
        //   (topic, data) => {
        //     let message = JSON.parse(data);
        //     console.log(topic, message);
        //     if (message.current) {
        //       this.$store.dispatch("CheckAlarm", message);
        //     }
        //   },
        //   (error) => {
        //     console.log("Mqtt订阅错误：", error);
        //   }
        // );
        this.$mqtt.subscribe(
          "warn/count/73fqz5yu",
          (topic, data) => {
            let message = JSON.parse(data);
            // console.log("显示", topic, message);
            this.count = message.untreated;
          },
          (error) => {
            console.log("Mqtt订阅错误：", error);
          }
        );
      }
    },
    // 获取用水单元
    getUnitList() {
      this.$store.dispatch("SaveUnit");
    },
    // 获取节点
    getNodeList() {
      this.$store.dispatch("SaveNode");
    },
    // 获得设备类型、用水单元等参数，存入axios
    getTypeList() {
      this.$store.dispatch("SaveType");
    },
    // 得到设备菜单
    getDeviceMenu() {
      this.$store.dispatch("SaveDeviceMenu");
    },
    // 获取菜单管理下菜单项
    getMenuManage() {
      this.$store.dispatch("SaveMenuList");
    },
    // 获得告警类型
    getwarningType() {
      this.$store.dispatch("SaveWarnTypeList");
    },
    getDepartment() {
      this.$store.dispatch("Savesepartment");
    },

    // 定时刷新数据函数 get消息条数 5分钟刷新一次
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.$http.get("/warn/count").then((res) => {
          if (res.code == 1) {
            console.log(res, "条数");
            if (res.data == [] || res.data == "") {
              this.count = 0;
            } else {
              this.count = res.data.untreated;
            }
          }
        });
      }, 3000000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    // 跳转
    handleClick(e) {
      this.current = e.key;
      this.openCurrent = e.key;
      if (e.key == "/login") {
        this.logout();
      }
    },
    // 清空一次告警跳转路径
    clearPath() {
      this.openCurrent = "";
    },
    logout() {
      this.$http
        .post(this.$api.Logout)
        .then((response) => {
          if (response.code == 1) {
            this.$store.dispatch("Logout");
            this.$store.dispatch("ClearRoutes");
            localStorage.clear();
            this.$router.replace({ path: "/logout", name: "Login" });
          }
        })
        .catch(() => {});
    },
  },
  components: {
    SideMenu,
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.$mqtt.disconnect();
  },
};
</script>
<style scoped lang="less">
/deep/ .ant-layout {
  overflow: hidden;
  min-width: 768px !important;
}
/deep/ .container-tree {
  flex: 1;
  height: 100% !important;
}
</style>
